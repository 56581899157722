    <template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <search-client
        :searchString="searchClientString"
        :noResults="true"
        @setSearchString ="setSearchClientString"
        @get-results="getClientResults"
        @clear-input="clearClient"
        ></search-client>
      </v-col>
      <v-col cols="12" sm="2" class="d-flex justify-end">
        <v-btn @click="openClientModal(defaultClient)" color="primary">Nieuwe klant</v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="clientHeaders"
        :items="clients"
        item-key="_id"
        :options.sync="options"
        :server-items-length="clientsLength"
        :footer-props="{
          showFirstLastPage: true,
      'items-per-page-options': [10,25,50,100]}"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{item}">
            <b style="cursor: pointer" @click="openClientModal(item)">{{item.name}}</b>
        </template>
      </v-data-table>
    </v-card>
    <client-modal
      v-if="clientModal"
      :clientModal="clientModal"
      :client="client"
      @insert-client="insertClient"
      @update-client="updateClient"
      @delete-client="deleteClient"
      @close-client-modal="closeClientModal"
    ></client-modal>
  </div>
</template>
    <script>
import searchClient from "../components/searchClient.vue"
import clientModal from "../components/clientModal"
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "clients",
  data() {
    return {
      client: {},
      defaultClient: {
        name:"",
        address:"",
        postalcode: "",
        city: "",        
        country: "NL",
        phone: "",
        mobile: "",
        email: "",
        VATNumber: "",
        COCNumber: "",
        EORINumber: "",
        incoterm: "DAP",
        payTerms: this.$store.state.settings.payTerms,
        scope: 1,
        active: true,
        sort: 0,
        invoiceFrequency: "Dagelijks",
      },
      clients:[],
      clientsLength: 0,
      searchClientString:"",
      options: { itemsPerPage: 25, page: 1,sortBy: ["clientNumber"],sortDesc: [false] },
      clientModal: false,
      clientHeaders: [
        { text: "Klantnr", value: "clientNumber" },
        { text: "Naam", value: "name" },
        { text: "Telefoon", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Adres", value: "address" },
        { text: "Postcode", value: "postalcode" },
        { text: "Stad", value: "city" },
        { text: "Land", value: "country" }
      ]
    }
  },
  methods: {
    async fetchClients(){
     let data =  {
              options:this.options
          }
    let response = await fetchGET('fetchClients',data)
    this.clients = response[0].results.length ? response[0].results : []
    this.clientsLength = response[0].length.length
        ? response[0].length[0].length
        : 0
    },
    async openClientModal(client) {
      this.client = client
      this.clientModal = true
    },
     setSearchClientString(val){
      this.searchClientString = val
    },
    getClientResults(clients){
      this.clients = clients
    },
    closeClientModal() {
      this.client = {}
      this.clientModal = false
    },
    async deleteClient(_id) {
      try {
        let response = await fetchPOST('deleteClient',{_id:_id})
        if(response.result.n==0) throw "deleteClient"
        let index = this.clients.findIndex(client=>client._id===_id)
        if(index>-1) this.clients.splice(index,1)
        successHandler("Klant verwijderd")
        this.closeClientModal()
      } catch (e) {
        errorHandler(e,"Klant niet verwijderd")
      }
      this.clientModal = false
    },
    async insertClient(client) {
      try {
        let response = await fetchPOST('insertClient',client)
        if(response.result.n==0) throw "insertClient"
        this.clients.push(response.ops[0])
        successHandler("Klant opgeslagen")
        this.closeClientModal()
      } catch (e) {
        errorHandler(e,"Klant niet opgeslagen")
      }
    },
    async updateClient(client) {
      try {
        let response = await fetchPOST('updateClient',client)
        if(response.result.nModified==0) throw "updateClient"
        Object.assign(this.client,client)
        successHandler("Klant gewijzigd")
        this.closeClientModal()
      } catch (e) {
        errorHandler(e,"Klant niet gewijzigd")
      }
    },
    clearClient(){
    this.options.page = 1
    this.searchClientString = ""
    this.selectedClient = {}
    this.fetchClients()
},
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchClients()
      }
    }
  },
  mounted() {
    this.fetchClients()
  },
  components: { searchClient, clientModal }
}
</script>
<style scoped="">

</style>